import { PortalFlow } from "./usePortalFlow";
import oidc from "src/services/openIdConnect";
import routes from "src/routes";
import { useEffect } from "react";

/**
 * Hook that redirects a user to the OAuth start/launch page if MyMassGov login is enabled.
 */
export const useMMGEnabledRedirect = (portalFlow: PortalFlow) => {
  useEffect(() => {
    if (oidc.isLmgEnabled()) {
      portalFlow.goTo(routes.auth.oAuthStart, {}, { redirect: true }); // Send user to oauth start page
    }
  }, [portalFlow]);
};

/**
 * Hook that redirects a user to the OAuth start/launch page if MyMassGov login is being enforced.
 */
export const useMMGEnforcedRedirect = (portalFlow: PortalFlow) => {
  useEffect(() => {
    if (oidc.isLmgEnforced() && !oidc.isOIDCAuthenticated()) {
      portalFlow.goTo(routes.auth.oAuthStart, {}, { redirect: true }); // Send user to oauth start page
    }
  }, [portalFlow]);
};
