import Link, { LinkProps } from "next/link";

import React from "react";
import { Trans } from "react-i18next";

export interface LogInLinkProps {
  /**
   * `className` attribute that is forwarded to the outermost element
   */
  readonly className?: string;
  /**
   * hyperlink reference to the log in page
   */
  readonly href: LinkComponentProps["href"];
}

/**
 * A link for users who already have an account to log in.
 */
export default function LogInLink(props: LogInLinkProps) {
  return (
    <p className={props.className}>
      <Trans
        i18nKey="components.logInLink.text"
        components={{ "log-in-link": <LinkComponent href={props.href} /> }}
      />
    </p>
  );
}

interface LinkComponentProps {
  readonly children?: React.ReactNode;
  readonly href: LinkProps["href"];
}

/**
 * A private `LogInLink` component that allows use of Next.js `Link` with
 * `Trans`. `Link` only allows a single React element child; `Trans` produces
 * a `string` as `children`. Use of `Trans` is preferable over `t()` in this case
 * to allow for translations to be sensitive to different language syntaxes.
 */
function LinkComponent(props: LinkComponentProps) {
  return (
    <Link href={props.href} className="display-inline-block">
      {props.children}
    </Link>
  );
}
