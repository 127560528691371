import { AppLogic } from "../hooks/useAppLogic";
import ButtonLink from "../components/ButtonLink";
import ExternalLink from "../components/core/ExternalLink";
import Lead from "../components/core/Lead";
import LogInLink from "../components/LogInLink";
import React from "react";
import Title from "../components/core/Title";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import routes from "../routes";
import useLoggedInRedirect from "../hooks/useLoggedInRedirect";
import { useMMGEnforcedRedirect } from "src/hooks/useMMGRedirects";
import { useTranslation } from "../locales/i18n";

interface IndexProps {
  appLogic: AppLogic;
}

export const Index = (props: IndexProps) => {
  const { appLogic } = props;
  const { t } = useTranslation();

  useLoggedInRedirect(
    appLogic.portalFlow,
    appLogic.portalFlow.getRouteFor("LOGGED_IN")
  );

  useMMGEnforcedRedirect(appLogic.portalFlow);

  const learnMoreLink = (
    <ExternalLink href={routes.external.massgov.paidLeave} />
  );

  const showLeaveAdminLegalContentChanges = isFeatureEnabled(
    "enableLeaveAdminLegalContentChanges"
  )
    ? "v2"
    : "v1";

  return (
    <React.Fragment>
      <div className="maxw-desktop">
        <Title seoTitle={t("pages.index.seoTitle")}>
          {t("pages.index.title")}
        </Title>
      </div>
      <div className="margin-top-6 measure-7">
        <Lead className="margin-bottom-4">
          <Trans
            i18nKey="pages.index.createAccountHint"
            components={{ "learn-more-link": learnMoreLink }}
            tOptions={{
              context: showLeaveAdminLegalContentChanges,
            }}
          />
        </Lead>
        <ButtonLink
          className="margin-top-3"
          href={appLogic.portalFlow.getRouteFor("SIGN_UP")}
        >
          {t("pages.index.getStartedButton")}
        </ButtonLink>
        <LogInLink
          className="margin-top-2"
          href={appLogic.portalFlow.getRouteFor("LOG_IN")}
        />
      </div>
    </React.Fragment>
  );
};

export default Index;
